<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-row>
                    <v-col cols="12">
                        <div class="tabs-nav">
                            <div class="tabs-nav__inner">
                                <div class="tabs-nav__item cursor_pointer" :class="{'active': type === 'messages'}">
                                    <button @click="type = 'messages'">
                                        <v-icon class="tabs-nav__item-icon" small>icon-messages</v-icon>
                                        {{$t('messages')}}
                                    </button>
                                </div>
                                <div class="tabs-nav__item" :class="{'active': type === 'email_messages'}">
                                    <button @click="type = 'email_messages'">
                                        <v-icon class="tabs-nav__item-icon" small>icon-envelope</v-icon>
                                        {{$t('email')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="type === 'messages'">
                        <Messages :entity="entity" :entity_uuid="entity_uuid" :socket-name="socketName"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="type === 'email_messages'">
                        <EmailMessages :entity="entity" :entity_uuid="entity_uuid"/>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

import EmailMessages from "@/components/EmailMessages.vue";
import Messages from "@/components/Messages.vue";

export default {
    name: "AllMessages",
    components: {
        EmailMessages,
        Messages
    },
    props: {
        entity: {
            type: String,
            required: true
        },

        entity_uuid: {
            type: String,
            required: true
        },

        socketName: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            type: 'messages',
        }
    },

    methods: {


    },

}
</script>

<style scoped lang="scss">
.tabs-nav__inner .tabs-nav__item{
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.tabs-nav__inner::after, .tabs-nav__item:not(.active) button::after{
    background-color: #F3F3F3 ;
}
.tabs-nav__item.active button::after{
    bottom: 1px;
}


</style>



